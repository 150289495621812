
/* -----------------------------------------
                Titles
----------------------------------------- */

.content_title {
    font-size: 16px;
    font-weight: 300;
    color: #1f1f1f;
    text-transform: uppercase;
}
.content_title a {
    color: #1f1f1f;
}
.content_title a:hover {
    text-decoration: underline;
    color: #1f1f1f;
}
.module_title {
    font-family: "Roboto",Arial,sans-serif;
    font-size: 48px;
    text-transform: uppercase;
    font-weight: bold;
    color: #252525;
    text-align: center;
}

.slogan_type{
    width: 100%;
    padding-top: 80px;
    padding-bottom: 60px;
    float: left;
}

.slogan_type ul{
    list-style: none;
}

span.slogan {
    font-size: 30px;
    color: #FFF;
    width: 100%;
    padding: 0 20px;
    text-align: center;
    font-style: italic;
    margin-bottom: 35px;
    display: block;
}

span.slogan_author {
    font-size: 14px;
    color: #FFF;
    width: 100%;
    text-align: center;
    font-style: italic;
    margin-bottom: 10px;
    display: block;
}

.article_type h5 {
    font-family: "Roboto",Arial,sans-serif;
    font-size: 19px;
    font-weight: 300;
    color: #3f434e;
    text-align: center;
    margin-bottom: 35px;
}

.article_type .module_title{
    margin-top: 70px;
    margin-bottom: 70px;
}

.mini_shields_type .module_title{
    margin-top: 70px;
    margin-bottom: 70px;
}

.article_type{
    padding-bottom: 75px;
    //float: left;
    width: 100%;
    background: #fcfcfc;
    border-top: 1px solid #e3e3e3;
}

.no-padding{
    padding: 0;
}

@media (min-width: 1200px){
    .container{
        width: 1200px;
    }
}


.articles_title {
     color: #1f1f1f;
     font-size: 20px;
}
.footer_title {
    color: #fff;
    font-weight: 300;
    text-transform: uppercase;
    padding: 0;
    margin: 0 0 10px 0;
}
.image_title {
    text-align: center;
}
