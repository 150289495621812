/*
To change this template, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 20-Dec-2016, 15:38:09
    Author     : Lukas Ružauskas <lukas@svetaine.lt>
*/

.slogan_type {
    /* The image used */
    /*background-image: url('http://www.w3schools.com/howto/img_parallax.jpg');*/

/*     Full height 
    height: 100%; 

     Create the parallax scrolling effect 
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;*/
}
