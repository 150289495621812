@charset "UTF-8";
/* -----------------------------------------
                Helpers
----------------------------------------- */
/* -----------------------------------------
                Base
----------------------------------------- */
/* -----------------------------------------
                Import Fonts  
----------------------------------------- */
@import url("http://fonts.googleapis.com/css?family=BenchNine:400,700,300&subset=latin-ext");
/* -----------------------------------------
                Layout
----------------------------------------- */
/* -----------------------------------------
                MENIU
----------------------------------------- */
/* Second meniu - Navigation */
@import url(http://fonts.googleapis.com/css?family=Roboto:400,100italic,300,100,300italic,400italic,500,500italic,700,700italic&subset=latin,latin-ext);
#headerNavigation {
  margin: 0; }

#headerNavigation .flyout li {
  border-left: none; }

#headerNavigation .flyout .flyout {
  left: 248px;
  top: 100%;
  margin-top: -173px; }

#headerNavigation .flyout .flyout li a {
  color: #555555;
  background: #F2F2F2; }

#headerNavigation .flyout .flyout li.active a,
#headerNavigation .flyout .flyout li.active a:hover {
  color: #fff;
  background: #4D4D4D; }

#headerNavigation .flyout .flyout li a:hover {
  background: #EBEBEB; }

/* -----------------------------------------
                Wrappers
----------------------------------------- */
#header_wrapper {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #fff;
  z-index: 999;
  height: 103px;
  border-bottom: 1px solid #e6e5df; }

#slider_wrapper {
  /*margin-top: 90px;*/
  display: none; }

#slider_wrapper a {
  cursor: initial; }

#content_wrapper {
  display: none; }

#loader_wrapper {
  background: url("../../production/img/loadingPage.gif") top center no-repeat;
  min-height: 50px;
  background-size: 50px 50px;
  float: left;
  width: 100%;
  margin-top: 130px;
  padding-top: 60px;
  text-align: center; }

#footer_wrapper {
  min-height: 74px;
  line-height: 74px;
  background: #25a6e4;
  width: 100%;
  float: left;
  font-size: 14px;
  color: #fff;
  display: none;
  font-weight: normal;
  position: relative; }

/* -----------------------------------------
                Import Fonts
----------------------------------------- */
/* -----------------------------------------
                Reset styles
----------------------------------------- */
body {
  background: #fff;
  margin: 0; }

::selection {
  background: #888 !important;
  /* WebKit/Blink Browsers */
  color: #fff; }

::-moz-selection {
  background: #888 !important;
  /* Gecko Browsers */
  color: #fff; }

/*Opera Fix*/
body:before {
  content: "";
  height: 100%;
  float: left;
  width: 0;
  margin-top: -32767px; }

body, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, form, p, blockquote, th, td {
  font-size: 15px;
  font-weight: 300;
  font-family: "Roboto",Helvetica,Arial,sans-serif;
  color: #272727; }

li {
  list-style-position: inside; }

label {
  display: inline; }

h1, h2, h3, h4, h5, h6 {
  font-weight: 300; }

h2 {
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  color: #003a69; }

h5 {
  font-size: 16px;
  color: #286eaa; }

h4 {
  font-size: 20px;
  color: #003a69; }

span.service_phone {
  line-height: 44px;
  font-size: 31px;
  color: #25A6E4;
  margin-right: 30px;
  font-weight: 300; }

a {
  color: #8CC63F;
  cursor: pointer; }

a:hover,
a:focus {
  color: #56A800; }

p {
  font-size: 16px;
  line-height: normal;
  text-align: justify;
  color: #555; }

#map_canvas {
  width: 100%;
  height: 482px; }

.map_canvas_contacts {
  width: 100%;
  height: 250px; }

.clear {
  clear: both; }

.left {
  float: left; }

.right {
  float: right; }

.hidden {
  display: none; }

.white {
  background-color: #fff; }

.transparent {
  background-color: transparent; }

.pointer:hover {
  cursor: pointer; }

.marB12 {
  margin-bottom: 12px; }

.marT5 {
  margin-top: 5px; }

.error.marT-9 {
  margin-top: -9px; }

.error.marT1 {
  margin-top: 1px; }

/* -----------------------------------------
                Layout
----------------------------------------- */
#wrap {
  min-height: 100%;
  background: #e6e2df; }

#main {
  max-width: 1200px;
  margin: 0 auto;
  background: #fff; }

.row {
  /*width: 1200px;*/
  margin: 0; }

.footer {
  margin-left: -15px; }

.footer.right {
  margin-left: 0;
  margin-right: -15px; }

.footer a, .footer p {
  font-size: 14px;
  color: #fff;
  font-weight: normal; }

.footer a:hover, .footer a:active {
  color: #fff;
  text-decoration: underline; }

/* -----------------------------------------
                FOOTER
----------------------------------------- */
#copyright {
  padding-bottom: 5px; }

#copyright .footer a {
  line-height: 20px; }

#copyright .footer p {
  margin: 0px;
  line-height: 20px;
  font-size: 13px;
  color: #fff; }

/* -----------------------------------------
                Components
----------------------------------------- */
/* -----------------------------------------
            Buttons
----------------------------------------- */
/* -----------------------------------------
                Titles
----------------------------------------- */
.content_title {
  font-size: 16px;
  font-weight: 300;
  color: #1f1f1f;
  text-transform: uppercase; }

.content_title a {
  color: #1f1f1f; }

.content_title a:hover {
  text-decoration: underline;
  color: #1f1f1f; }

.module_title {
  font-family: "Roboto",Arial,sans-serif;
  font-size: 48px;
  text-transform: uppercase;
  font-weight: bold;
  color: #252525;
  text-align: center; }

.slogan_type {
  width: 100%;
  padding-top: 80px;
  padding-bottom: 60px;
  float: left; }

.slogan_type ul {
  list-style: none; }

span.slogan {
  font-size: 30px;
  color: #FFF;
  width: 100%;
  padding: 0 20px;
  text-align: center;
  font-style: italic;
  margin-bottom: 35px;
  display: block; }

span.slogan_author {
  font-size: 14px;
  color: #FFF;
  width: 100%;
  text-align: center;
  font-style: italic;
  margin-bottom: 10px;
  display: block; }

.article_type h5 {
  font-family: "Roboto",Arial,sans-serif;
  font-size: 19px;
  font-weight: 300;
  color: #3f434e;
  text-align: center;
  margin-bottom: 35px; }

.article_type .module_title {
  margin-top: 70px;
  margin-bottom: 70px; }

.mini_shields_type .module_title {
  margin-top: 70px;
  margin-bottom: 70px; }

.article_type {
  padding-bottom: 75px;
  width: 100%;
  background: #fcfcfc;
  border-top: 1px solid #e3e3e3; }

.no-padding {
  padding: 0; }

@media (min-width: 1200px) {
  .container {
    width: 1200px; } }

.articles_title {
  color: #1f1f1f;
  font-size: 20px; }

.footer_title {
  color: #fff;
  font-weight: 300;
  text-transform: uppercase;
  padding: 0;
  margin: 0 0 10px 0; }

.image_title {
  text-align: center; }

/* -----------------------------------------
            Pagination
----------------------------------------- */
ul.pagination li.current a,
ul.pagination li.current a:hover,
ul.pagination li a:hover {
  background-color: #8cc14b; }

.block-grid {
  display: block;
  overflow: hidden;
  padding: 0; }

.block-grid > li {
  display: block;
  float: left;
  height: auto; }

.block-grid.one-up {
  margin: 0; }

.block-grid.one-up > li {
  padding: 0 0 15px;
  width: 100%; }

.block-grid.two-up {
  margin: 0 -15px; }

.block-grid.two-up > li {
  padding: 0 15px 15px;
  width: 50%; }

.block-grid.three-up {
  margin: 0 -12px; }

.block-grid.three-up > li {
  padding: 0 12px 12px;
  width: 33.3333%; }

.block-grid.four-up {
  margin: 0 -10px; }

.block-grid.four-up > li {
  padding: 0 10px 10px;
  width: 25%; }

.block-grid.five-up {
  margin: 0 -8px; }

.block-grid.five-up > li {
  padding: 0 8px 8px;
  width: 20%; }

.block-grid.six-up {
  margin: 0 -8px; }

.block-grid.six-up > li {
  padding: 0 8px 8px;
  width: 16.6667%; }

.block-grid.seven-up {
  margin: 0 -8px; }

.block-grid.seven-up > li {
  padding: 0 8px 8px;
  width: 14.2857%; }

.block-grid.eight-up {
  margin: 0 -8px; }

.block-grid.eight-up > li {
  padding: 0 8px 8px;
  width: 12.5%; }

.block-grid.nine-up {
  margin: 0 -8px; }

.block-grid.nine-up > li {
  padding: 0 8px 8px;
  width: 11.1111%; }

.block-grid.ten-up {
  margin: 0 -8px; }

.block-grid.ten-up > li {
  padding: 0 8px 8px;
  width: 10%; }

.block-grid.eleven-up {
  margin: 0 -8px; }

.block-grid.eleven-up > li {
  padding: 0 8px 8px;
  width: 9.09091%; }

.block-grid.twelve-up {
  margin: 0 -8px; }

.block-grid.twelve-up > li {
  padding: 0 8px 8px;
  width: 8.33333%; }

@media only screen and (min-width: 992px) {
  .block-grid.eight-up > li:nth-child(8n+1), .block-grid.eleven-up > li:nth-child(11n+1), .block-grid.five-up > li:nth-child(5n+1), .block-grid.four-up > li:nth-child(4n+1), .block-grid.nine-up > li:nth-child(9n+1), .block-grid.seven-up > li:nth-child(7n+1), .block-grid.six-up > li:nth-child(6n+1), .block-grid.ten-up > li:nth-child(10n+1), .block-grid.three-up > li:nth-child(3n+1), .block-grid.twelve-up > li:nth-child(12n+1), .block-grid.two-up > li:nth-child(2n+1) {
    clear: both; } }

@media only screen and (max-width: 992px) and (min-width: 767px) {
  .block-grid > li {
    clear: none; }
  .block-grid.tab-one-up > li {
    width: 100%; }
  .block-grid.tab-two-up > li {
    width: 50%; }
  .block-grid.tab-three-up > li {
    width: 33.3333%; }
  .block-grid.tab-four-up > li {
    width: 25%; }
  .block-grid.tab-five-up > li {
    width: 20%; }
  .block-grid.tab-six-up > li {
    width: 16.6667%; }
  .block-grid.tab-seven-up > li {
    width: 14.2857%; }
  .block-grid.tab-eight-up > li {
    width: 12.5%; }
  .block-grid.tab-nine-up > li {
    width: 11.1111%; }
  .block-grid.tab-ten-up > li {
    width: 10%; }
  .block-grid.tab-eleven-up > li {
    width: 9.09091%; }
  .block-grid.tab-twelve-up > li {
    width: 8.33333%; }
  .block-grid.tab-eight-up > li:nth-child(8n+1), .block-grid.tab-eleven-up > li:nth-child(11n+1), .block-grid.tab-five-up > li:nth-child(5n+1), .block-grid.tab-four-up > li:nth-child(4n+1), .block-grid.tab-nine-up > li:nth-child(9n+1), .block-grid.tab-seven-up > li:nth-child(7n+1), .block-grid.tab-six-up > li:nth-child(6n+1), .block-grid.tab-ten-up > li:nth-child(10n+1), .block-grid.tab-three-up > li:nth-child(3n+1), .block-grid.tab-twelve-up > li:nth-child(12n+1), .block-grid.tab-two-up > li:nth-child(2n+1) {
    clear: both; } }

@media only screen and (max-width: 767px) {
  .block-grid > li {
    clear: none; }
  .block-grid.mobile-one-up > li {
    width: 100%; }
  .block-grid.mobile-two-up > li {
    width: 50%; }
  .block-grid.mobile-three-up > li {
    width: 33.3333%; }
  .block-grid.mobile-four-up > li {
    width: 25%; }
  .block-grid.mobile-five-up > li {
    width: 20%; }
  .block-grid.mobile-six-up > li {
    width: 16.6667%; }
  .block-grid.mobile-seven-up > li {
    width: 14.2857%; }
  .block-grid.mobile-eight-up > li {
    width: 12.5%; }
  .block-grid.mobile-nine-up > li {
    width: 11.1111%; }
  .block-grid.mobile-ten-up > li {
    width: 10%; }
  .block-grid.mobile-eleven-up > li {
    width: 9.09091%; }
  .block-grid.mobile-twelve-up > li {
    width: 8.33333%; }
  .block-grid.mobile-eight-up > li:nth-child(8n+1), .block-grid.mobile-eleven-up > li:nth-child(11n+1), .block-grid.mobile-five-up > li:nth-child(5n+1), .block-grid.mobile-four-up > li:nth-child(4n+1), .block-grid.mobile-nine-up > li:nth-child(9n+1), .block-grid.mobile-seven-up > li:nth-child(7n+1), .block-grid.mobile-six-up > li:nth-child(6n+1), .block-grid.mobile-ten-up > li:nth-child(10n+1), .block-grid.mobile-three-up > li:nth-child(3n+1), .block-grid.mobile-twelve-up > li:nth-child(12n+1), .block-grid.mobile-two-up > li:nth-child(2n+1) {
    clear: both; } }

#design {
  background: url("/design.jpg") top center;
  width: 100%;
  height: 4480px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99999;
  opacity: 0.65;
  display: none; }

.hasShadow {
  box-shadow: 0 3px 5px #898989;
  -mox-box-shadow: 0 3px 5px #898989;
  -webkit-box-shadow: 0 3px 5px #898989; }

/*
To change this template, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 23-Dec-2016, 11:28:33
    Author     : Lukas Ružauskas <lukas@svetaine.lt>
*/
#fullModal {
  width: 100%;
  height: 100%;
  background: #FFF;
  position: fixed;
  z-index: 10000;
  display: none;
  overflow-y: auto; }

#fullModal #portfolio_menu_bar {
  background: #000;
  color: #FFF;
  font-size: 24px;
  height: 60px;
  width: 100%;
  position: fixed;
  z-index: 90000; }

#fullModal .portfolio_close {
  float: right;
  margin-right: 20px;
  margin-top: 10px;
  cursor: pointer; }

#fullModal .port_images_holder {
  position: static; }

#fullModal .owl-carousel .owl-item img {
  /*height: 70%;*/ }

#fullModal .portfolio_title {
  font-size: 40px;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  padding: 40px 0 0px; }

#fullModal .fullModal-content {
  margin-top: 60px; }

#fullModal .portfolio_category {
  font-size: 20px;
  text-align: center;
  padding-bottom: 40px; }

#fullModal .portfolio_description {
  margin-top: 0px;
  display: block;
  margin-left: 15px;
  margin-right: 15px; }

#fullModal .owl-next, #fullModal .owl-prev {
  position: absolute;
  top: 40%;
  background: none;
  font-size: 40px;
  color: #000; }

#fullModal #portfolio_prev, #fullModal #portfolio_next {
  display: inline-block;
  margin: 15px 55px;
  font-size: 38px; }

#fullModal .owl-carousel .owl-item img {
  height: 50%; }

#fullModal .bx-wrapper {
  box-shadow: none;
  border: none; }

.left_arrow {
  background: url(../img/left_arrow.png);
  width: 15px;
  height: 28px;
  display: block; }

.left_arrow:hover {
  background: url(../img/left_arrow_hover_light.png); }

.right_arrow:hover {
  background: url(../img/right_arrow_hover_light.png); }

.right_arrow {
  background: url(../img/right_arrow.png);
  width: 15px;
  height: 28px;
  display: block; }

/* -----------------------------------------
                Menu Modules
----------------------------------------- */
/* News */
.news_image {
  margin: 5px 10px 10px 0; }

.text_block {
  opacity: 0;
  /*padding-bottom: 20px;*/ }

/* -----------------------------------------
   Gallery pictures (keisti cia norint didinti tarpus tarp nuotrauku!!!)
----------------------------------------- */
.block-grid.four-up {
  margin: 0 -6px; }

.block-grid.four-up > li {
  padding: 0px 6px 6px 6px; }

.block-grid.four-up.partners li {
  padding-bottom: 15px; }

.bottom_btn {
  width: 68px;
  height: 68px;
  border-radius: 50%;
  /*    background: url('../img/bottom_btn.png') no-repeat top left;*/
  cursor: pointer;
  position: absolute;
  bottom: -34px;
  left: 48%;
  z-index: 9999;
  text-align: center; }

.up_arrow {
  background: url("../img/up_arrow.png");
  height: 15px;
  width: 28px;
  display: inline-block;
  margin-top: 24px; }

.gallery_image_holder {
  text-align: center;
  padding: 10px; }

.gallery_type .module_title {
  padding-top: 35px;
  margin-top: 0; }

.justified-gallery {
  margin-bottom: 35px; }

.justified-gallery img {
  -webkit-filter: grayscale(100%);
  /* Safari 6.0 - 9.0 */
  filter: grayscale(100%); }

.justified-gallery img:hover {
  -webkit-filter: none;
  /* Safari 6.0 - 9.0 */
  filter: none; }

.category_image {
  overflow: hidden; }

.shield_item {
  padding: 45px;
  min-height: 424px !important;
  color: #fff;
  text-align: center; }

.shield_image_holder {
  text-align: center; }

.galleries_image_holder img {
  max-width: 100%;
  min-width: 100%; }

.galleries_image_holder {
  position: relative; }

.galleries_title_holder {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: url("../../production/img/magnify.png") top right;
  background-repeat: no-repeat;
  background-position: top 5px right 5px;
  background-size: 5%;
  transition: all .5s ease-in-out; }

.galleries_title_holder:hover {
  background: #3f3f3f url("../../production/img/magnify.png") top right;
  background-repeat: no-repeat;
  background-position: top 5px right 5px;
  background-size: 5%;
  opacity: 0.86;
  cursor: pointer; }

.galleries.block-grid.four-up {
  padding-left: 5px;
  padding-right: 5px; }

.galleries_type .module_title {
  margin-bottom: 70px;
  margin-top: 70px; }

.galleries_type {
  padding-bottom: 70px;
  /*border-top: 1px solid #e3e3e3;*/
  background: #fff;
  float: left;
  width: 100%; }

.services_type {
  /*border-top: 1px solid #e3e3e3;*/
  float: left;
  width: 100%;
  background-color: #fcfcfc; }

.services_type .modal-title {
  color: #25A6E4;
  font-weight: 300; }

.galleries.block-grid.four-up li {
  padding: 10px; }

.text_block img {
  max-width: 100%; }

.galleries_title_holder h5 {
  font-size: 100%;
  font-size: 3vw;
  color: #fff;
  font-weight: 100;
  text-transform: uppercase;
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 0; }

.gallery_images_holder {
  width: 100%;
  height: 100%;
  overflow: hidden;
  top: 0;
  left: 0;
  position: absolute; }

.shield_item h5 {
  color: #555555;
  font-size: 20px;
  text-align: center;
  font-weight: 200;
  margin-top: 24px;
  margin-bottom: 20px; }

li.shield_item .text_block {
  text-align: center;
  font-size: 14px;
  line-height: 24px;
  font-weight: 300;
  color: #555; }

/*.justified_holder .close_btn {
     width: 28px; 
     height: 28px; 
     background: url(../production/img/close_btn.jpg) top left; 
     cursor: pointer; 
     float: right; 
     margin-bottom: 20px; 
     margin-right: 20px; 
}    */
.gallery_arrow {
  background: transparent url(../../production/img/gallery_arrow.png) top left;
  height: 23px;
  width: 40px;
  position: absolute;
  top: -21px; }

.justified_holder {
  padding: 20px 0;
  border-bottom: 1px solid #E1E1E1;
  border-top: 1px solid #E1E1E1;
  width: 100%;
  float: left;
  background: #FCFCFC;
  position: relative;
  display: none;
  margin: 20px 0;
  margin-bottom: 40px;
  padding-bottom: 40px; }

.justified_holder .close_btn {
  width: 48px;
  height: 48px;
  cursor: pointer;
  float: right;
  font-size: 28px; }

/*
.tos-close span {
    margin-top: 7px;
    margin-left: 8px;
}*/
.tos-close span:before, .tos-close span:after {
  border: none; }

.tos-close span, .portfolio_close span {
  width: 29px;
  height: 28px;
  display: block;
  background: url("../img/close_btn_white.png") top left;
  margin-top: 6px;
  margin-left: 6px; }

.tos-close span:hover, .portfolio_close span:hover {
  background: url("../img/close_btn_white_hover_light.png") top left; }

.tos-close {
  height: 41px;
  width: 41px; }

.tos-close span:after, .tos-close span:before, .tos-next span, .tos-prev span {
  width: 24px;
  height: 24px;
  top: 40%; }

/* -----------------------------------------
            Partners pictures
----------------------------------------- */
.cell {
  z-index: 10;
  border: 1px solid #ccc;
  padding: 5px;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  margin: 0 auto; }

.cell .outerContainer {
  display: table;
  overflow: hidden; }

.cell .outerContainer .innerContainer {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  text-align: center; }

.cell .outerContainer .innerContainer .element {
  line-height: 0 !important;
  font-size: 0; }

.category_holder {
  width: 282px;
  float: left;
  background: transparent;
  position: relative;
  margin-right: 14px;
  margin-bottom: 14px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  -moz-box-sizing: border-box; }

.service_title {
  font-weight: 700;
  font-size: 20px;
  text-transform: uppercase;
  color: #555555;
  margin: 20px 0;
  font-family: "Roboto",Helvetica,Arial,sans-serif; }

.category_holder:nth-of-type(4n) {
  margin-right: 0px; }

.categories_holder {
  float: left;
  width: 100%;
  text-align: center; }

.categories_holder_inline {
  display: inline-block;
  width: 100%; }

.product_slide_out {
  float: left;
  width: 100%;
  background: #FFF;
  clear: both;
  padding: 30px;
  margin: 10px 0 25px 0;
  border: 1px solid #E3E3E3;
  position: relative;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  -moz-box-sizing: border-box; }

.product_slide_out .content_wrap {
  overflow: hidden;
  text-align: left; }

.product_close_btn {
  width: 28px;
  height: 28px;
  background: url("../img/close_btn.jpg") top left;
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer; }

.product_images div {
  padding: 0; }

.product_images .product_single_image {
  padding-right: 5px;
  padding-bottom: 5px; }

.product_images .product_single_image img {
  border: 1px solid #f0f1f3; }

.video_holder {
  padding: 0; }

.product_right_col {
  margin-bottom: 30px; }

.files_header {
  color: #3c414c;
  font-size: 20px;
  font-weight: 400; }

.products_files_holder {
  list-style: none;
  padding: 0; }

.products_files_holder li a {
  color: #3C414C;
  font-size: 15px; }

.products_files_holder li:before {
  content: '';
  width: 12px;
  height: 12px;
  background: url("../../img/files_arrow.jpg") top left;
  display: block;
  float: left;
  margin-top: 6px;
  margin-right: 7px; }

.product_images .product_single_big_image {
  margin-bottom: 5px;
  border: 1px solid #f0f1f3; }

.product_images img {
  width: 100%; }

.product_triangle {
  display: none;
  width: 41px;
  height: 25px;
  background: url("../../production/img/triangle.png") top left;
  position: absolute;
  top: -24px;
  left: 30px; }

.product_title::first-letter {
  text-transform: uppercase; }

.asterix {
  color: #25A6E4; }

.product_title {
  font-size: 23px;
  margin: 0;
  width: 100%;
  font-weight: 700;
  color: #555;
  text-align: left;
  margin-bottom: 36px; }

.mini_shields_type {
  padding-bottom: 70px;
  background: #fcfcfc;
  border-top: 1px solid #e3e3e3; }

.mini_shields_type h5 {
  opacity: 0; }

.mini_shields_type img {
  width: 0;
  border-radius: 150px;
  -webkit-border-radius: 150px;
  -moz-border-radius: 150px;
  border: 9px solid #D0D0D0;
  box-sizing: border-box; }

.mini_shields_type .text_block {
  opacity: 0; }

.categories_type {
  background: #fbfcfd; }

.clients_type {
  float: left;
  width: 100%;
  background: #fff; }

.gauges_type {
  float: left;
  width: 100%;
  background: #afafaf;
  color: #fff; }

.map_type {
  float: left;
  width: 100%; }

#map_image {
  position: relative; }

.wecontact_type {
  float: left;
  width: 100%;
  border-top: 1px solid #e3e3e3;
  background: #fcfcfc;
  padding-bottom: 80px; }

.wecontact_type .module_title {
  margin-top: 70px;
  margin-bottom: 50px; }

.shield_image_holder {
  width: 196px;
  display: inline-block; }

.contacts_form {
  overflow: hidden; }

.gauge_item {
  width: 270px;
  height: 270px;
  background: #c6c6c6;
  border-radius: 270px;
  -webkit-border-radius: 270px;
  -o-border-radius: 270px;
  -moz-border-radius: 270px;
  -ms-border-radius: 270px;
  margin: 52px;
  display: inline-block;
  margin-top: 0px;
  text-align: center; }

.shield_item:hover img {
  /*zoom:1.1;  */ }

.gauge_logo_holder {
  margin-top: 60px; }

.gauge_result {
  margin-top: 15px; }

.gauge_result h5 {
  color: #fff;
  font-size: 40px; }

.gauge_title {
  margin-top: 10px; }

.gauge_title h5 {
  color: #fff;
  font-size: 21px; }

.input.custom {
  box-sizing: border-box;
  height: 40px;
  padding: 0px 16px;
  max-width: 100%; }

div#phone_wrapper {
  float: left;
  width: 100%;
  display: none;
  padding: 60px 0px;
  border-top: 1px solid #E3E3E3;
  background: #fff; }

span.big_phone {
  line-height: 63px;
  font-size: 60px;
  color: #25a6e4;
  font-weight: 300;
  zoom: 0.1; }

.width_229 {
  width: 229px; }

.width_450 {
  width: 450px; }

.category_image img {
  width: 100%;
  transition: all .5s ease-in-out; }

.category_image img:hover {
  width: 100%;
  -webkit-transform: scale(1.4);
  -moz-transform: scale(1.4);
  -ms-transform: scale(1.4);
  -o-transform: scale(1.4);
  transform: scale(1.4); }

.client_item {
  margin: 40px;
  display: inline-block; }

ul.products_holder {
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0; }

ul.products_holder li {
  border: 1px solid #eef0f2;
  border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  margin-bottom: 10px;
  width: 100%;
  padding: 12px 20px; }

ul.products_holder li:hover, ul.products_holder li.active {
  background: #fbfcfd;
  color: #014f8b;
  cursor: pointer; }

/*
To change this template, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 14-Dec-2016, 13:24:39
    Author     : Lukas Ružauskas <lukas@svetaine.lt>
*/
.category_image.circle img {
  transition: all .5s ease-in-out;
  display: inline;
  height: 100%;
  width: auto;
  position: absolute;
  top: -9999px;
  bottom: -9999px;
  left: -9999px;
  right: -9999px;
  margin: auto;
  -webkit-backface-visibility: hidden; }

.category_image.circle img:hover {
  -webkit-transform: scale(1.4);
  -moz-transform: scale(1.4);
  -ms-transform: scale(1.4);
  -o-transform: scale(1.4);
  transform: scale(1.4); }

.category_image.circle {
  width: 177px;
  height: 177px;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  margin: 0 auto; }

/*
To change this template, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 16-Dec-2016, 16:16:40
    Author     : Lukas Ružauskas <lukas@svetaine.lt>
*/
.client_label {
  display: block; }

.client_item {
  vertical-align: middle; }

.client_item_image_holder {
  width: 150px;
  height: 150px;
  display: inline-table; }

.client_item_image_holder a {
  display: table-cell;
  vertical-align: middle; }

.client_item_image_holder span {
  display: table-cell;
  vertical-align: middle; }

.client_item_image_holder img {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%); }

.client_item_image_holder img:hover {
  filter: none; }

/*
To change this template, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 19-Dec-2016, 10:58:06
    Author     : Lukas Ružauskas <lukas@svetaine.lt>
*/
#fullModal .bx-wrapper, #fullModal .bx-viewport {
  max-height: 800px !important;
  /*height: 200px!important;*/
  /*    max-width: 600px!important;*/
  /*width: 600px!important;*/ }

.portfolio_type .categories_holder ul {
  padding: 0; }

#portfolio_prev, #portfolio_next {
  cursor: pointer; }

.portfolio_image_holder img {
  max-width: 100%;
  min-width: 100%; }

.portfolio_image_holder {
  position: relative; }

.portfolio_image_holder > .portfolio_title_holder {
  transition: all .5s ease-in-out; }

.portfolio_title_holder {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  visibility: hidden; }

.portfolio_image_holder:hover > .portfolio_title_holder {
  visibility: visible;
  background: #3f3f3f;
  background-repeat: no-repeat;
  background-size: 30%;
  opacity: 0.86;
  cursor: pointer; }

.portfolio.block_grid.four-up {
  padding-left: 1px;
  padding-right: 1px;
  list-style: none; }

.portfolio_type .module_title {
  margin-bottom: 40px;
  margin-top: 40px; }

.portfolio_type {
  padding-bottom: 70px;
  border-top: 1px solid #e3e3e3;
  background: #fff;
  position: relative;
  float: left;
  width: 100%; }

.portfolio.block_grid.four-up li {
  padding: 1px;
  padding-top: 0px;
  padding-bottom: 2px;
  width: 25%;
  float: left; }

.big_photos_type {
  width: 100%;
  float: left;
  background: #f9f9f9; }

.big_photos_holder {
  float: left;
  width: 100%;
  text-align: center; }

.big_photos_holder img {
  display: inline-block;
  max-width: 100%; }

@media (max-width: 768px) {
  .portfolio.block_grid.four-up li {
    width: 50%; } }

.text_block img {
  max-width: 100%; }

.port_images_holder {
  width: 100%;
  height: 100%;
  overflow: hidden;
  top: 0;
  left: 0;
  position: absolute; }

.portfolio_title_holder h5 {
  font-size: 100%;
  /*font-size: 2.1vw;*/
  font-size: 36px;
  color: #fff;
  font-weight: 100 !important;
  width: 100%;
  text-align: center;
  position: absolute;
  top: 30%;
  padding: 0 12%; }

.portfolio_title_holder h6 {
  color: #FFF;
  width: 100%;
  /*display: inline-block;*/
  text-align: center;
  font-weight: 100 !important;
  position: absolute;
  bottom: 15%;
  /* font-size: 1.2vw;*/ }

@media (min-width: 992px) and (max-width: 1200px) {
  .category_holder:nth-of-type(4n) {
    margin-right: 14px; }
  .category_holder:nth-of-type(3n) {
    margin-right: 0px; }
  .slogan_type, .newsletter_type, .article_type, .contacts_type {
    padding-left: 15px;
    padding-right: 15px; }
  .category_holder:nth-of-type(3n+1) {
    clear: both; } }

.portfolio_type .categories_holder ul {
  list-style: none; }

.portfolio_type .categories_holder ul li {
  display: inline-block;
  color: #545862;
  border: 1px solid #e0e0e0;
  padding: 12px;
  cursor: pointer;
  margin-right: 2px;
  margin-bottom: 5px; }

.portfolio_type .categories_holder ul li:hover {
  color: #fff;
  border: 1px solid #e0e0e0;
  background: #e0e0e0;
  cursor: pointer; }

.portfolio_type .categories_holder ul li.active {
  color: #fff;
  border: 1px solid #3d424d;
  background: #3d424d;
  cursor: initial; }

.portfolio_more_btn_holder {
  width: 100%;
  text-align: center;
  margin-top: 60px; }

.portfolio_more_btn {
  display: inline-block;
  color: #545862;
  border: 1px solid #e0e0e0;
  padding: 12px;
  cursor: pointer;
  margin-right: 2px;
  margin-bottom: 5px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.portfolio_more_btn:hover {
  color: #545862;
  border: 1px solid #e0e0e0;
  background: #e0e0e0;
  cursor: pointer; }

.portfolio_more_btn:active {
  color: #fff;
  border: 1px solid #3d424d;
  background: #3d424d; }

.portfolio_more_btn.active {
  display: none; }

.portfolio_wrapper {
  float: left;
  width: 100%; }

ul.portfolio.block-grid.four-up.mobile-two-up.more_content {
  display: none; }

ul.portfolio.block-grid.four-up.mobile-two-up.more_content.active {
  display: block; }

@media (max-width: 768px) {
  .portfolio_more_btn, .portfolio_type .categories_holder ul li {
    padding: 4px;
    font-size: 13px; } }

ul.portfolio {
  list-style-type: none;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center; }

.col-md-2.portfolio_item, .col-md-3.portfolio_item {
  padding: 0;
  float: none;
  display: inline-block;
  margin: 0 -2px; }

@media (min-width: 992px) and (max-width: 1366px) {
  .col-md-2.portfolio_item {
    width: 20%; } }

@media (min-width: 450px) and (max-width: 960px) {
  .col-md-2.portfolio_item {
    width: 50%; } }

.portfolio_description {
  display: none; }

/* X */
/*.close-hairline, .tos-close span {
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
  overflow: hidden;
  &:hover {
    &::before, &::after {
      background: #fff;
    }
  }

  &::before, &::after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
  }
  &::before {
    -ms-transform: rotate(45deg);  IE 9 
    -webkit-transform: rotate(45deg);  Chrome, Safari, Opera 
    transform: rotate(45deg);
    height: 1px;
  }
  &::after {
    -ms-transform: rotate(-45deg);  IE 9 
    -webkit-transform: rotate(-45deg);  Chrome, Safari, Opera 
    transform: rotate(-45deg);
    height: 1px;
  }
}*/
/*
To change this template, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 19-Dec-2016, 14:43:08
    Author     : Lukas Ružauskas <lukas@svetaine.lt>
*/
@media screen and (max-width: 594px) {
  .achievement_holder {
    float: none !important;
    margin: 0 auto !important;
    background: none; } }

.achievement_title {
  font-size: 40px; }

.achievement_description p {
  text-align: center; }

.achievement_holder {
  background: none;
  padding-bottom: 20px; }

.achievements_type {
  padding-bottom: 70px; }

/*
To change this template, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 20-Dec-2016, 15:38:09
    Author     : Lukas Ružauskas <lukas@svetaine.lt>
*/
.slogan_type {
  /* The image used */
  /*background-image: url('http://www.w3schools.com/howto/img_parallax.jpg');*/
  /*     Full height 
    height: 100%; 

     Create the parallax scrolling effect 
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;*/ }

/* -----------------------------------------
                Main Modules
----------------------------------------- */
/* First meniu */
.menu-first {
  margin-top: 27px !important;
  list-style: none;
  margin-right: -14px; }

.menu-first > li {
  float: left; }

.menu-first > li > a {
  margin-left: 40px;
  font-size: 18px;
  text-transform: uppercase;
  color: #282828;
  font-weight: 300;
  font-family: "Roboto",Helvetica,Arial,sans-serif; }

.menu-first li a:hover,
.menu-first li a.active {
  /*color: #8CC63F;*/
  text-decoration: underline; }

/* Third meniu */
#bottom_menu {
  color: #525252; }

#bottom_menu ul li {
  list-style: none;
  margin: 8px 0; }

#bottom_menu ul li a {
  color: #525252;
  line-height: 14px;
  font-size: 16px; }

#bottom_menu ul li a:hover {
  text-decoration: underline; }

/* Banners */
#banners_wrapper {
  padding-top: 20px; }

.banners li {
  position: relative; }

.banners li a.on_flash {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999; }

/* -----------------------------------------
                Carousel
----------------------------------------- */
#carousel_wrapper {
  padding: 0 0 40px 0; }

#carousel_wrapper .owl-carousel {
  padding: 0 30px; }

#carousel_wrapper .owl-carousel .item {
  padding: 10px 0px;
  margin: 10px;
  text-align: center;
  height: 90px; }

#carousel_wrapper .owl-carousel .item .helper {
  display: inline-block;
  height: 100%;
  vertical-align: middle; }

#carousel_wrapper .owl-carousel .item img {
  vertical-align: middle;
  max-height: 90px; }

#carousel_wrapper .next,
#carousel_wrapper .prev {
  position: absolute;
  top: 55%;
  width: 32px;
  height: 32px;
  cursor: pointer; }

#carousel_wrapper .next {
  right: 0px;
  background: url("../img/next-horizontal.png") no-repeat 0 0; }

#carousel_wrapper .prev {
  left: 0px;
  background: url("../img/prev-horizontal.png") no-repeat 0 0; }

#carousel_wrapper .next:hover, #carousel_holder .next:focus,
#carousel_wrapper .prev:hover, #carousel_holder .prev:focus {
  background-position: -32px 0; }

#carousel_wrapper .next:active,
#carousel_wrapper .prev:active {
  background-position: -64px 0; }

/* Languages */
#languages {
  margin: 0px;
  list-style: none;
  position: absolute;
  right: 3px;
  top: 3px;
  z-index: 1; }

#languages .lang {
  margin-left: 5px;
  display: block;
  float: left; }

#languages .lang a {
  padding: 3px 5px;
  color: #1c1c1c;
  text-transform: uppercase; }

#languages .lang a.active,
#languages .lang a:hover {
  color: #fff;
  background: #014F8B; }

/* -----------------------------------------
                MODULES
----------------------------------------- */
/* Logo */
#logo {
  margin: 20px 0 15px -15px; }

#mini_banners_wrapper {
  background: #e6e2df; }

#mini_banners_wrapper .min_banners li {
  position: relative;
  padding-top: 10px;
  padding-bottom: 7px;
  margin-left: 0px;
  color: #4a4949; }

.min_banners li .min_banner_img {
  margin-right: 10px; }

.min_banners li .min_banner_title {
  text-transform: uppercase;
  font-weight: 300;
  margin-top: 10px;
  font-size: 16px;
  line-height: 16px; }

.min_banners li .min_banner_text {
  font-weight: 300;
  font-size: 12px; }

.min_banners li a.on_flash {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999; }

.wecontact_input {
  border: 1px solid #ebebeb;
  margin-bottom: 20px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px; }

span.we_contact_text {
  color: #555555;
  font-size: 16px;
  margin-bottom: 50px;
  display: inline-block;
  zoom: 0.1; }

.newsletter_type {
  background: #fbfcfd; }

.newsletter_type label {
  line-height: 37px;
  margin: 0;
  margin-right: 20px;
  color: #6f6b5e;
  font-weight: 300;
  font-size: 18px;
  float: left; }

.newsletter_input {
  margin-right: 20px;
  margin-bottom: 10px; }

.newsletter_form {
  margin-top: 40px;
  margin-bottom: 40px;
  float: right; }

.contacts_form input {
  width: 100%;
  margin-bottom: 10px;
  background: #fbfcfc; }

.contacts_form textarea {
  margin-bottom: 10px;
  width: 100%;
  height: 110px;
  padding: 16px;
  background: #fbfcfc;
  border: 1px solid #eaeaea;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px; }

.contacts_type {
  padding-bottom: 40px;
  background: #fff;
  width: 100%;
  float: left;
  border-top: 1px solid #E3E3E3;
  background-color: #fcfcfc; }

.contacts_type h5 {
  color: #252525;
  font-size: 18px;
  margin-bottom: 25px; }

.contacts_type .input-group {
  margin-bottom: 20px;
  width: 100%; }

.contacts_type .input-group input {
  border: 1px solid #eaeaea;
  margin-bottom: 0px;
  border-bottom: 0; }

.contacts_type .input-group input:first-child {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  -webkit-border-top-right-radius: 5px;
  -moz-border-top-right-radius: 5px; }

.contacts_type .input-group input:last-child {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  -webkit-border-bottom-right-radius: 5px;
  -moz-border-bottom-right-radius: 5px;
  border-bottom: 1px solid #eaeaea; }

.wecontact_form {
  text-align: center; }

.reg_form_error {
  display: none; }

.service_holder:hover, .service_holder:active {
  cursor: pointer; }

.btn.custom {
  padding: 4px 65px 5px;
  background: #888888;
  color: #fff;
  font-size: 18px;
  line-height: 35px;
  font-weight: 300;
  border-radius: 0;
  text-transform: uppercase;
  -moz-border-radius: 5px;
  -o-border-radius: 5px;
  -ms-border-radius: 5px;
  -webkit-border-radius: 5px;
  border: none; }

.btn.custom:hover, .btn.custom:active {
  background: #545454; }

.block-grid {
  margin: 0px !important; }

/* Query */
#query_form {
  margin: 0px; }

.socialicon {
  font-size: 30px !important;
  padding: 10px !important; }

@media (min-width: 992px) and (max-width: 1200px) {
  .category_holder:nth-of-type(4n) {
    margin-right: 14px; }
  .category_holder:nth-of-type(3n) {
    margin-right: 0px; }
  .slogan_type, .newsletter_type, .article_type, .contacts_type {
    padding-left: 15px;
    padding-right: 15px; }
  .category_holder:nth-of-type(3n+1) {
    clear: both; } }

@media (min-width: 595px) and (max-width: 991px) {
  .category_holder:nth-of-type(4n) {
    margin-right: 14px; }
  .gauge_item {
    zoom: 50%; }
  #footer_wrapper {
    line-height: normal;
    text-align: center;
    padding-top: 40px;
    padding-bottom: 20px; }
  #slider_wrapper {
    visibility: hidden;
    height: 102px; }
  .mini_shields_type img {
    min-width: 100%; }
  .shield_image_holder {
    width: 100%; }
  .shield_image_holder img {
    max-width: 100%; }
  .footer.right {
    width: 100%; }
  .slogan_type, .newsletter_type, .article_type, .contacts_type {
    padding-left: 15px;
    padding-right: 15px; }
  .product_left_col {
    padding: 0; }
  .category_holder:nth-of-type(2n) {
    margin-right: 0px; }
  .category_holder:nth-of-type(2n+1) {
    clear: both; }
  .categories_holder {
    padding-left: 15px;
    padding-right: 15px; }
  .contacts_form .row .col-md-6, .contacts_form .row .col-md-12 {
    padding: 0; }
  #logo {
    display: inline-block;
    float: left;
    margin: 0 auto;
    position: absolute;
    top: 25px;
    left: 0;
    right: 0;
    max-width: 300px; }
  #mobile_menu_button {
    float: right;
    width: 48px;
    height: 48px;
    padding: 0px;
    cursor: pointer;
    margin-top: 30px;
    font-size: 28px; }
  .navbar-form {
    display: none !important; }
  .navbar-form.active {
    display: block !important;
    float: left;
    border: none;
    width: 100%;
    padding: 0;
    margin: 0; }
  .menu-first {
    margin: 0 !important;
    padding: 0;
    width: 100%;
    background: #fff; }
  .menu-first > li {
    clear: both;
    line-height: 45px;
    width: 100%;
    background: #FCFCFC;
    border-top: 1px solid #E3E3E3; }
  .menu-first > li a {
    color: #282828;
    width: 100%;
    display: block;
    margin: 0;
    padding-left: 40px;
    height: 100%; }
  .menu-first > li a:hover {
    text-decoration: none; }
  .mobile-no-padding {
    padding: 0; } }

@media screen and (max-width: 1400px) {
  #carousel_wrapper .next {
    right: 45.5%; }
  #carousel_wrapper .prev {
    left: 45.5%; } }

@media screen and (max-width: 975px) {
  #carousel_wrapper .next {
    right: 44%; }
  #carousel_wrapper .prev {
    left: 44%; } }

@media screen and (max-width: 767px) {
  .map_type {
    margin-bottom: 50px !important; }
  .bottom_btn {
    left: 47% !important; }
  #carousel_wrapper .articles_title {
    margin-bottom: 0px !important;
    margin-top: 0px !important; }
  .owlNavigation {
    display: none !important; } }

@media (max-width: 594px) {
  .module_title {
    font-size: 35px; }
  .category_holder:nth-of-type(4n) {
    margin-right: 14px; }
  .gauge_item {
    zoom: 50%; }
  span.big_phone {
    font-size: 30px; }
  #slider_wrapper {
    visibility: hidden;
    height: 102px; }
  .shield_image_holder {
    width: 100%; }
  .shield_image_holder img {
    max-width: 100%; }
  .slogan_type, .newsletter_type, .article_type, .contacts_type {
    padding-left: 15px;
    padding-right: 15px; }
  #footer_wrapper {
    line-height: normal;
    text-align: center;
    padding-top: 40px;
    padding-bottom: 20px; }
  .mini_shields_type img {
    min-width: 100%; }
  .footer.right {
    width: 100%; }
  .product_left_col {
    padding: 0; }
  .category_holder:nth-of-type(n) {
    margin-right: 0px; }
  .category_holder:nth-of-type(n+1) {
    clear: both; }
  .newsletter_type label {
    float: none !important; }
  .newsletter_input {
    clear: both;
    float: none;
    text-align: left; }
  .newsletter_form {
    float: none !important;
    display: inline-block; }
  .newsletter_type {
    text-align: center; }
  .newsletter_type button {
    float: none !important;
    clear: both; }
  .categories_holder {
    padding-left: 15px;
    padding-right: 15px; }
  .contacts_form .row .col-md-6, .contacts_form .row .col-md-12 {
    padding: 0; }
  #logo {
    width: 50%;
    display: inline-block;
    margin-top: 11%;
    margin: 0 auto;
    position: absolute;
    top: 35px;
    left: 0;
    right: 0; }
  #logo img {
    max-width: 100%; }
  #mobile_menu_button {
    float: right;
    width: 48px;
    height: 48px;
    padding: 0px;
    cursor: pointer;
    margin-top: 30px;
    font-size: 28px; }
  .navbar-form {
    display: none !important; }
  .navbar-form.active {
    display: block !important;
    float: left;
    border: none;
    width: 100%;
    padding: 0;
    margin: 0; }
  .menu-first {
    margin: 0 !important;
    padding: 0;
    width: 100%;
    background: #fff; }
  .menu-first > li {
    clear: both;
    line-height: 45px;
    width: 100%;
    background: #FCFCFC;
    border-top: 1px solid #E3E3E3; }
  .menu-first > li a {
    color: #282828;
    width: 100%;
    display: block;
    margin: 0;
    padding-left: 37px;
    height: 100%; }
  .menu-first > li a:hover {
    text-decoration: none; }
  .mobile-no-padding {
    padding: 0; }
  .bottom_btn {
    background-size: 100%;
    width: 10%;
    left: 47%; }
  #logo {
    margin: 0 auto;
    position: absolute;
    top: 35px;
    left: 0;
    right: 0; } }

@media screen and (min-width: 991px) {
  #mobile_menu_button {
    display: none; } }

/* -----------------------------------------
                Vendors
----------------------------------------- */
/*
To change this template, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 06-Dec-2016, 14:39:20
    Author     : Lukas Ružauskas <lukas@svetaine.lt>
*/
.tos-wrapper.tos-fill .tos-slide.tos-image > img {
  min-height: 0;
  min-width: 0; }

.tos-desktop .tos-wrapper:hover .tos-close:hover, .tos-touch .tos-wrapper.tos-hover .tos-close:hover {
  opacity: 0.5; }

.tos-desktop .tos-wrapper:hover .tos-close, .tos-touch .tos-wrapper.tos-hover .tos-close {
  opacity: 1; }

/* -----------------------------------------
   Carousel
----------------------------------------- */
#carousel_wrapper {
  padding: 0 0 40px 0;
  float: left;
  width: 100%; }

#carousel_wrapper .owl-carousel {
  padding: 0 30px; }

#carousel_wrapper .owl-carousel .item {
  padding: 10px 0px;
  margin: 0px;
  text-align: center;
  height: 90px; }

#carousel_wrapper .owl-carousel .item .helper {
  display: inline-block;
  height: 100%;
  vertical-align: middle; }

#carousel_wrapper .owl-carousel .item img {
  vertical-align: middle;
  max-height: 90px; }

#carousel_wrapper .next,
#carousel_wrapper .prev {
  position: absolute;
  top: 41%;
  width: 41px;
  height: 41px;
  cursor: pointer; }

#carousel_wrapper .next {
  right: 47%;
  background: url("../img/carousel-right.png") no-repeat 0 0; }

#carousel_wrapper .prev {
  left: 47%;
  background: url("../img/carousel-left.png") no-repeat 0 0; }

#carousel_wrapper .next:hover {
  background: url("../img/carousel-righth.png") no-repeat 0 0; }

#carousel_wrapper .prev:hover {
  background: url("../img/carousel-lefth.png") no-repeat 0 0; }

#carousel_wrapper .next:hover, #carousel_holder .next:focus,
#carousel_wrapper .prev:hover, #carousel_holder .prev:focus {
  background-color: transparent;
  box-shadow: none !important;
  filter: brightness(110%); }

#carousel_wrapper .next:active,
#carousel_wrapper .prev:active {
  background-color: transparent;
  box-shadow: none !important; }

#carousel_wrapper .articles_title {
  text-align: center;
  color: #b0b0b0;
  text-transform: uppercase;
  font-weight: 700;
  font-family: "Roboto",Helvetica,Arial,sans-serif;
  margin-top: 55px;
  font-size: 24px;
  margin-bottom: 50px; }

.owl-next {
  position: absolute;
  top: 40%;
  right: 0;
  font-size: 70px;
  color: #000; }

.owl-prev {
  position: absolute;
  top: 40%;
  left: 0;
  font-size: 70px;
  color: #000; }

.slogan_type .owl-next {
  color: #FFF; }

.slogan_type .owl-prev {
  color: #FFF; }

span.slogan_img {
  display: block;
  text-align: center; }

span.slogan_img img {
  border-radius: 50%; }

.owl-carousel .owl-item img {
  width: auto;
  display: block;
  margin: 0 auto; }

.owl-carousel .owl-stage {
  display: table; }

.owl-carousel .owl-item {
  display: table-cell;
  float: none;
  vertical-align: middle; }

/* Nivo-slider caption */
.slider-wrapper {
  position: relative; }

.nivo-caption .caption_title {
  font-size: 20px;
  line-height: 30px;
  font-weight: 700; }

.nivo-caption .caption_description {
  line-height: 20px; }

.theme-light a.nivo-prevNav {
  bottom: 34%;
  background: url(../img/left_arrow.png) no-repeat; }

.theme-light a.nivo-nextNav {
  bottom: 34%;
  background: url(../img/right_arrow.png) no-repeat; }

.theme-light a.nivo-nextNav:hover {
  background: url(../img/right_arrow_hover.png) no-repeat; }

.theme-light a.nivo-prevNav:hover {
  background: url(../img/left_arrow_hover.png) no-repeat; }

.bx-wrapper .bx-controls-direction a {
  width: 15px;
  height: 28px; }

.bx-wrapper .bx-next {
  background: url(../img/right_arrow.png);
  right: 18px; }

.bx-wrapper .bx-prev {
  background: url(../img/left_arrow.png); }

.bx-wrapper .bx-next:hover {
  background: url(../img/right_arrow_hover_light.png); }

.bx-wrapper .bx-prev:hover {
  background: url(../img/left_arrow_hover_light.png); }

.bx-wrapper .bx-next:focus, .bx-wrapper .bx-next:hover {
  background-position: 0 0; }
