/*
To change this template, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 19-Dec-2016, 14:43:08
    Author     : Lukas Ružauskas <lukas@svetaine.lt>
*/
@media screen and (max-width: 594px) {
    .achievement_holder {
        float: none!important;
        margin: 0 auto!important;
        background: none;
    }
}

.achievement_title {
    font-size: 40px;
}

.achievement_description p {
    text-align: center;
}

.achievement_holder {
    background: none;
    padding-bottom: 20px;
}

.achievements_type {
    padding-bottom: 70px;
}