


/* -----------------------------------------
   Gallery pictures (keisti cia norint didinti tarpus tarp nuotrauku!!!)
----------------------------------------- */

.block-grid.four-up {
    margin: 0 -6px;
}
.block-grid.four-up > li {
    padding: 0px 6px 6px 6px;
}
.block-grid.four-up.partners li {
    padding-bottom: 15px;
}
.bottom_btn{
    width: 68px;
    height: 68px;
    border-radius: 50%;
/*    background: url('../img/bottom_btn.png') no-repeat top left;*/
    cursor: pointer;
    position: absolute;
    bottom: -34px;
    left: 48%;
    z-index: 9999;
    text-align: center;
}

.up_arrow {
    background: url('../img/up_arrow.png');
    height: 15px;
    width: 28px;
    display: inline-block;
    margin-top: 24px;
}
.gallery_image_holder {
    text-align: center;
    padding: 10px;
}

.gallery_type .module_title {
    padding-top: 35px;
    margin-top: 0;
}

.justified-gallery {
    margin-bottom: 35px;
}

.justified-gallery img {
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
}
.justified-gallery img:hover {
    -webkit-filter: none; /* Safari 6.0 - 9.0 */
    filter: none;
}

.category_image {
    overflow: hidden;
}