/* -----------------------------------------
                Import Fonts
----------------------------------------- */

@import url(http://fonts.googleapis.com/css?family=Roboto:400,100italic,300,100,300italic,400italic,500,500italic,700,700italic&subset=latin,latin-ext);

/* -----------------------------------------
                Reset styles
----------------------------------------- */

html, body {
}
body {
    background: #fff;
    margin: 0;
}

::selection {
  background: #888!important; /* WebKit/Blink Browsers */
  color: #fff;
}
::-moz-selection {
  background: #888!important; /* Gecko Browsers */
  color: #fff;
}

/*Opera Fix*/
body:before {
    content:"";
    height:100%;
    float:left;
    width:0;
    margin-top:-32767px;
}
body, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, form, p, blockquote, th, td {
    font-size: 15px;
    font-weight: 300;
    font-family: "Roboto",Helvetica,Arial,sans-serif;
    color: #272727;
} 

li {
    list-style-position: inside;
}
label {
    display: inline;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: 300;
}
h2 {
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    color: #003a69;
}
h5 {
    font-size: 16px;
    color: #286eaa;
}
h4 {
    font-size: 20px;
    color: #003a69;
}

span.service_phone {
    line-height: 44px;
    font-size: 31px;
    color: #25A6E4;
    margin-right: 30px;
    font-weight: 300;
}

a {
    color: #8CC63F;
    cursor: pointer;
}
a:hover,
a:focus {
    color: #56A800;
}

p {
    font-size: 16px; 
    line-height: normal; 
    text-align: justify;
    color: #555;
}

#map_canvas {
    width: 100%;
    height: 482px;
}

.map_canvas_contacts{
    width: 100%;
    height: 250px;
}

.clear {
    clear: both;
}
.left {
    float: left;
}
.right {
    float: right;
}
.hidden {
    display: none;
}
.white {
    background-color: #fff;
}
.transparent {
    background-color: transparent;
}
.pointer:hover {
    cursor: pointer;
}
.marB12 {
    margin-bottom: 12px;
}
.marT5 {
    margin-top: 5px;
}
.error.marT-9 {
    margin-top: -9px;
}
.error.marT1 {
    margin-top: 1px;
}

/* -----------------------------------------
                Layout
----------------------------------------- */

#wrap {
    min-height: 100%;
    background: #e6e2df;
}
#main {
    max-width: 1200px;
    margin: 0 auto;
    background: #fff;
}
.row {
    /*width: 1200px;*/
    margin: 0;
}

