

.wecontact_input{
    border: 1px solid #ebebeb;
    margin-bottom: 20px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
}

span.we_contact_text{
    color: #555555;
    font-size: 16px;
    margin-bottom: 50px;
    display: inline-block;
    zoom:0.1;
}

.newsletter_type{
    background: #fbfcfd;
}

.newsletter_type label{
    line-height: 37px;
    margin: 0;
    margin-right: 20px;
    color: #6f6b5e;
    font-weight: 300;
    font-size: 18px;
    float: left;
}

.newsletter_input{
    margin-right: 20px;
    margin-bottom: 10px;
}

.newsletter_form{
    margin-top: 40px;
    margin-bottom: 40px;
    float: right;
}

.contacts_form input{
    width: 100%;
    margin-bottom: 10px;
    background: #fbfcfc;
}

.contacts_form textarea{
    margin-bottom: 10px;
    width: 100%;
    height: 110px;
    padding: 16px;
    background: #fbfcfc;
    border: 1px solid #eaeaea;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
}

.contacts_type {
    padding-bottom: 40px;
    background: #fff;
    width: 100%;
    float: left;
    border-top: 1px solid #E3E3E3;
    background-color: #fcfcfc;
}

.contacts_type h5{
    color: #252525;
    font-size: 18px;
    margin-bottom: 25px;
}

.contacts_type .input-group{
    margin-bottom: 20px;
    width: 100%;
}

.contacts_type .input-group input{
    border: 1px solid #eaeaea;
    margin-bottom: 0px;
    border-bottom: 0;
}

.contacts_type .input-group input:first-child{
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    -webkit-border-top-right-radius: 5px;
    -moz-border-top-right-radius: 5px;
}

.contacts_type .input-group input:last-child{
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    -webkit-border-bottom-right-radius: 5px;
    -moz-border-bottom-right-radius: 5px;
    border-bottom: 1px solid #eaeaea;
}

.wecontact_form {
    text-align: center;
}

.reg_form_error{
    display: none;
}

.service_holder:hover, .service_holder:active{
    cursor: pointer;
}

.btn.custom{
    padding: 4px 65px 5px;
    background: #888888;
    color: #fff;
    font-size: 18px;
    line-height: 35px;
    font-weight: 300;
    border-radius: 0;
    text-transform: uppercase;
    -moz-border-radius: 5px;
    -o-border-radius: 5px;
    -ms-border-radius: 5px;
    -webkit-border-radius: 5px;
    border: none;
}

.btn.custom:hover, .btn.custom:active{
    background: #545454;
}

.block-grid{
    margin: 0px!important;
}


/* Query */

#query_form {
    margin: 0px;
}