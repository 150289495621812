
/* -----------------------------------------
                MENIU
----------------------------------------- */

/* Second meniu - Navigation */

#headerNavigation {
    margin: 0;
}
#headerNavigation .flyout li {
    border-left: none;
}
#headerNavigation .flyout .flyout {
    left: 248px;
    top: 100%;
    margin-top: -173px;
}
#headerNavigation .flyout .flyout li a {
    color: #555555;
    background: #F2F2F2;
}
#headerNavigation .flyout .flyout li.active a,
#headerNavigation .flyout .flyout li.active a:hover {
    color: #fff;
    background: #4D4D4D;
}
#headerNavigation .flyout .flyout li a:hover {
    background: #EBEBEB;
}
