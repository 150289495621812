

/* -----------------------------------------
                Wrappers
----------------------------------------- */

#header_wrapper {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #fff;
    z-index: 999;
    height: 103px;
    border-bottom: 1px solid #e6e5df;
}
#menu_wrapper {
    
}
#slider_wrapper {
    /*margin-top: 90px;*/
    display: none;
}

#slider_wrapper a{
    cursor: initial;
}
#content_wrapper {
    display: none;
}

#loader_wrapper {
    background: url('../../production/img/loadingPage.gif') top center no-repeat;
    min-height: 50px;
    background-size: 50px 50px;
    float: left;
    width: 100%;
    margin-top: 130px;
    padding-top: 60px;
    text-align: center;
}
#query_wrapper {
    
}
#footer_wrapper {
    min-height: 74px;
    line-height: 74px;
    background: #25a6e4;
    width: 100%;
    float: left;
    font-size: 14px;
    color: #fff;
    display: none;
    font-weight: normal;
    position: relative;
} 