/*
To change this template, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 23-Dec-2016, 11:28:33
    Author     : Lukas Ružauskas <lukas@svetaine.lt>
*/

#fullModal {
    width: 100%;
    height: 100%;
    background: #FFF;
    position: fixed;
    z-index: 10000;
    display: none;
    overflow-y: auto;
}

#fullModal #portfolio_menu_bar {
    background: #000;
    color: #FFF;
    font-size: 24px;
    height: 60px;
    width: 100%;
    position: fixed;
    z-index: 90000;
}

#fullModal .portfolio_close {
    float: right;
    margin-right: 20px;
    margin-top: 10px;
    cursor: pointer;
}

#fullModal .port_images_holder {
    position: static;
}

#fullModal .owl-carousel .owl-item img{
    /*height: 70%;*/
}

#fullModal .portfolio_title {
    font-size: 40px;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    padding: 40px 0 0px;;
}

#fullModal .fullModal-content {
    margin-top: 60px;
}
#fullModal .portfolio_category {
    font-size: 20px;
    text-align: center;
    padding-bottom: 40px;
}

#fullModal .portfolio_description {
    margin-top: 0px;
    display: block;
    margin-left: 15px;
    margin-right: 15px;
}

#fullModal .owl-next, #fullModal .owl-prev {
    position: absolute;
    top: 40%;
    background: none;
    font-size: 40px;
    color: #000;
}

#fullModal #portfolio_prev, #fullModal #portfolio_next {
    display: inline-block;
    margin: 15px 55px;
    font-size: 38px;
}

#fullModal .owl-carousel .owl-item img {
    height: 50%;
}

#fullModal .bx-wrapper {
    box-shadow: none;
    border: none;
}

.left_arrow {
    background: url(../img/left_arrow.png);
    width: 15px;
    height: 28px;
    display: block;
}
.left_arrow:hover {
    background: url(../img/left_arrow_hover_light.png);
}
.right_arrow:hover {
    background: url(../img/right_arrow_hover_light.png);
}

.right_arrow {
    background: url(../img/right_arrow.png);
    width: 15px;
    height: 28px;
    display: block;
}