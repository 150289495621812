

.client_item{
    margin: 40px;
    display: inline-block;
}

ul.products_holder{
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
}

ul.products_holder li{
    border:1px solid #eef0f2;
    border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    margin-bottom: 10px;
    width: 100%;
    padding: 12px 20px;
}

ul.products_holder li:hover,ul.products_holder li.active{
    background: #fbfcfd;
    color:#014f8b;
    cursor:pointer;
} 