
/* First meniu */

.menu-first {
    margin-top: 27px !important;
    list-style: none;
    margin-right: -14px;
}
.menu-first > li {
    float: left;
}
.menu-first > li > a {
    margin-left: 40px;
    font-size: 18px;
    text-transform: uppercase;
    color: #282828;
    font-weight: 300;
    font-family: "Roboto",Helvetica,Arial,sans-serif;
}
.menu-first li a:hover, 
.menu-first li a.active {
    /*color: #8CC63F;*/
    text-decoration: underline;
}