
.shield_item{
    padding: 45px;
    min-height: 424px!important;
    color: #fff;
    text-align: center;
}

.shield_image_holder {
    text-align: center;
}

.galleries_image_holder img {
    max-width: 100%;
    min-width: 100%;
}

.galleries_image_holder{
   position: relative;
}

.galleries_title_holder {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: url('../../production/img/magnify.png') top right;
    background-repeat: no-repeat;
    background-position: top 5px right 5px;
    background-size: 5%;
    transition: all .5s ease-in-out;
}

.galleries_title_holder:hover {
    background: #3f3f3f url('../../production/img/magnify.png') top right;
    background-repeat: no-repeat;
    background-position: top 5px right 5px;
    background-size: 5%;
    opacity: 0.86;
    cursor: pointer;
    
}

.galleries.block-grid.four-up{
    padding-left: 5px;
    padding-right: 5px;
}

.galleries_type .module_title{
    margin-bottom: 70px;
    margin-top: 70px;
}

.galleries_type{
    padding-bottom: 70px;
    /*border-top: 1px solid #e3e3e3;*/
    background: #fff;
    float: left;
    width: 100%;
}

.services_type{
    /*border-top: 1px solid #e3e3e3;*/
    float: left;
    width: 100%;
    background-color: #fcfcfc;
}

.services_type .modal-title{
    color: #25A6E4;
    font-weight: 300;
}

.galleries.block-grid.four-up li{
    padding: 10px;
}

.text_block img{
    max-width: 100%;
}


.galleries_title_holder h5{
    font-size: 100%;
    font-size: 3vw;
    color: #fff;
  //  display: none;
    font-weight: 100;
    text-transform: uppercase;
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: 0;
}

.gallery_images_holder {
    width: 100%;
    height: 100%;
    overflow: hidden;
    top: 0;
    left: 0;
    position: absolute;
}


.shield_item h5 {
    color: #555555;
    font-size: 20px;
    text-align: center;
    font-weight: 200;
    margin-top: 24px;
    margin-bottom: 20px;
}

li.shield_item .text_block {
    text-align: center;
    font-size: 14px;
    line-height: 24px;
    font-weight: 300;
    color: #555;
}

/*.justified_holder .close_btn {
     width: 28px; 
     height: 28px; 
     background: url(../production/img/close_btn.jpg) top left; 
     cursor: pointer; 
     float: right; 
     margin-bottom: 20px; 
     margin-right: 20px; 
}    */

.gallery_arrow {
    background: transparent url(../../production/img/gallery_arrow.png) top left;
    height: 23px;
    width: 40px;
    position: absolute;
    top: -21px;
}

.justified_holder {
    padding: 20px 0;
    border-bottom: 1px solid #E1E1E1;
    border-top: 1px solid #E1E1E1;
    width: 100%;
    float: left;
    background: #FCFCFC;
    position: relative;
    display: none;
    margin: 20px 0;
    margin-bottom: 40px;
    padding-bottom: 40px;
}

.justified_holder .close_btn {
    width: 48px;
    height: 48px;
    cursor: pointer;
    float: right;
    font-size: 28px;
}
/*
.tos-close span {
    margin-top: 7px;
    margin-left: 8px;
}*/
.tos-close span:before, .tos-close span:after {
    border: none;
}

.tos-close span, .portfolio_close span {
    width: 29px;
    height: 28px;
    display: block;
    background: url('../img/close_btn_white.png') top left;
    margin-top: 6px;
    margin-left: 6px;
}

.tos-close span:hover, .portfolio_close span:hover {
    background: url('../img/close_btn_white_hover_light.png') top left;
}

.tos-close {
    height: 41px;
    width: 41px;
}

.tos-close span:after, .tos-close span:before, .tos-next span, .tos-prev span {
    width: 24px;
    height: 24px;
    top: 40%;
}