

.footer {
    margin-left: -15px;
}

.footer.right {
    margin-left: 0;
    margin-right: -15px;
}

.footer a, .footer p{
    font-size: 14px;
    color: #fff;
    font-weight: normal;
}

.footer a:hover, .footer a:active{
    color: #fff;
    text-decoration: underline;
}


/* -----------------------------------------
                FOOTER
----------------------------------------- */

#copyright {
    padding-bottom: 5px;
}
#copyright .footer {

}
#copyright .footer a {
    line-height: 20px;
}
#copyright .footer p {
    margin: 0px;
    line-height: 20px;
    font-size: 13px;
    color: #fff;
}