/*
To change this template, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 06-Dec-2016, 14:39:20
    Author     : Lukas Ružauskas <lukas@svetaine.lt>
*/

.tos-wrapper.tos-fill .tos-slide.tos-image>img {
    min-height: 0;
    min-width: 0;
}

.tos-desktop .tos-wrapper:hover .tos-close:hover, .tos-touch .tos-wrapper.tos-hover .tos-close:hover {
    opacity: 0.5;
}

.tos-desktop .tos-wrapper:hover .tos-close, .tos-touch .tos-wrapper.tos-hover .tos-close {
    opacity: 1;
}