.category_holder {
    width: 282px;
    float: left;
    background: transparent;
    position: relative;
    margin-right: 14px;
    margin-bottom: 14px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
    -moz-box-sizing: border-box;
}

.service_title{
    font-weight: 700;
    font-size: 20px;
    text-transform: uppercase;
    color: #555555;
    margin: 20px 0;
    font-family: "Roboto",Helvetica,Arial,sans-serif;
}

.category_holder:nth-of-type(4n){
    margin-right: 0px;
}

.categories_holder {
    float: left;
    width: 100%;
    text-align: center;
}

.categories_holder_inline {
    display: inline-block;
    width: 100%;
}


.product_slide_out {
    float: left;
    width: 100%;
    background: #FFF;
    clear: both;
    padding: 30px;
    margin: 10px 0 25px 0;
    border: 1px solid #E3E3E3;
    position: relative;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
    -moz-box-sizing: border-box;
}

.product_slide_out .content_wrap{
    overflow: hidden;
    text-align: left;
}

.product_close_btn{
    width: 28px;
    height: 28px;
    background: url('../img/close_btn.jpg') top left;
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
}

.product_images div{
    padding: 0
}

.product_images .product_single_image{
    padding-right: 5px;
    padding-bottom: 5px;
}

.product_images .product_single_image img{
    border:1px solid #f0f1f3;
}

.video_holder{
    padding: 0;
}

.product_right_col{
    margin-bottom: 30px;
}

.files_header{
    color: #3c414c;
    font-size: 20px;
    font-weight: 400;
}

.products_files_holder{
    list-style: none;
    padding: 0;
}

.products_files_holder li a{
    color: #3C414C;
    font-size: 15px;
}

.products_files_holder li:before{
    content: '';
    width: 12px;
    height: 12px;
    background: url('../../img/files_arrow.jpg') top left;
    display: block;
    float: left;
    margin-top: 6px;
    margin-right: 7px;
}

.product_images .product_single_big_image{
    margin-bottom: 5px;
    border:1px solid #f0f1f3;
}

.product_images img{
    width: 100%;
}

.product_triangle{
    display: none;
    width: 41px;
    height: 25px;
    background: url('../../production/img/triangle.png') top left;
    position: absolute;
    top: -24px;
    left: 30px;
}

.product_title::first-letter{
    text-transform: uppercase;
}

.asterix{
    color: #25A6E4;
}



.product_title{
    font-size: 23px;
    margin: 0;
    width: 100%;
    font-weight: 700;
    color: #555;
    text-align: left;
    margin-bottom: 36px;
}

.mini_shields_type{
    padding-bottom: 70px;
    background: #fcfcfc;
    border-top: 1px solid #e3e3e3;
}

.mini_shields_type h5{
    opacity: 0;
}

.mini_shields_type img{
    width: 0;
    border-radius: 150px;
    -webkit-border-radius: 150px;
    -moz-border-radius: 150px;
    border: 9px solid #D0D0D0;
    box-sizing: border-box;
}

.mini_shields_type .text_block{
    opacity: 0;
}

.categories_type{
    background: #fbfcfd;
}

.clients_type{
    float: left;
    width: 100%;
    background: #fff;
}

.gauges_type{
    float: left;
    width: 100%;
    background: #afafaf;
    color: #fff;
}


.map_type {
    float: left;
    width: 100%;
}

#map_image{
    position: relative;
}

.wecontact_type {
    float: left;
    width: 100%;
    border-top: 1px solid #e3e3e3;
    background: #fcfcfc;
    padding-bottom: 80px;
}

.wecontact_type .module_title{
    margin-top: 70px;
    margin-bottom: 50px;
}

.shield_image_holder{
    width: 196px;
    display: inline-block;
}

.contacts_form {
    overflow: hidden;
}

.gauge_item{
    width: 270px;
    height: 270px;
    background: #c6c6c6;
    border-radius: 270px;
    -webkit-border-radius: 270px;
    -o-border-radius: 270px;
    -moz-border-radius: 270px;
    -ms-border-radius: 270px;
    margin: 52px;
    display: inline-block;
    margin-top: 0px;
    text-align: center;
}

.shield_item:hover img{
  /*zoom:1.1;  */
}

.gauge_logo_holder{
    margin-top: 60px;
}

.gauge_result{
    margin-top: 15px;
}

.gauge_result h5{
    color: #fff;
    font-size: 40px;
}

.gauge_title{
    margin-top: 10px;
}

.gauge_title h5{
    color: #fff;
    font-size: 21px;
}

.input.custom {
    box-sizing: border-box;
    height: 40px;
    padding: 0px 16px;
    max-width: 100%
}

div#phone_wrapper {
    float: left;
    width: 100%;
    display: none;
    padding: 60px 0px;
    border-top: 1px solid #E3E3E3;
    background: #fff;
}

span.big_phone {
    line-height: 63px;
    font-size: 60px;
    color: #25a6e4;
    font-weight: 300;
    zoom:0.1;
}

.width_229{
    width: 229px;
}

.width_450{
    width: 450px;
}
.category_image {

}

.category_image img{
    width: 100%;
    transition: all .5s ease-in-out;
}
.category_image img:hover{
    width: 100%;
    -webkit-transform: scale(1.4);
    -moz-transform: scale(1.4);
    -ms-transform: scale(1.4);
    -o-transform: scale(1.4);
    transform: scale(1.4);    
}