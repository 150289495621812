
@media (min-width: 992px) and (max-width: 1200px) {
    .category_holder:nth-of-type(4n){
        margin-right: 14px;
    }
    
    .category_holder:nth-of-type(3n){
        margin-right: 0px;
    }
    
    .slogan_type,.newsletter_type,.article_type,.contacts_type{
        padding-left: 15px;
        padding-right: 15px;
    }
    
    .category_holder:nth-of-type(3n+1){
        clear: both;
    }
}

@media (min-width: 595px) and (max-width: 991px) {
    .category_holder:nth-of-type(4n){
        margin-right: 14px;
    }
    .gauge_item{
        zoom: 50%;
    }
    
    #footer_wrapper{
        line-height: normal;
        text-align: center;
        padding-top: 40px;
        padding-bottom: 20px;
        
    }

    #slider_wrapper {
        visibility: hidden;
        height: 102px;
    }

    .mini_shields_type img{
        min-width: 100%;
    }

    .shield_image_holder{
        width: 100%;
    }

    .shield_image_holder img{
        max-width: 100%;
    }
    
    .footer.right{
        width: 100%;
    }
    
    .slogan_type,.newsletter_type,.article_type,.contacts_type{
        padding-left: 15px;
        padding-right: 15px;
    }
    
    .product_left_col{padding: 0;}
    
    .category_holder:nth-of-type(2n){
        margin-right: 0px;
    }
    
    .category_holder:nth-of-type(2n+1){
        clear: both;
    }
    
    .categories_holder{
        padding-left: 15px;
        padding-right: 15px;
    }
    .contacts_form .row .col-md-6,.contacts_form .row .col-md-12{
        padding: 0;
    }
    
    #logo{
        display: inline-block;
        float: left;
        margin: 0 auto;
        position: absolute;
        top: 25px;
        left: 0;
        right: 0;
        max-width: 300px;
    }
    
    #mobile_menu_button{
        float: right;
        width: 48px;
        height: 48px;
        padding: 0px;
        //background: url('../img/menu.png') no-repeat top left;
        cursor: pointer;
        margin-top: 30px;
        font-size: 28px;
    }
    
    .navbar-form{
        display: none!important;
    }
    
    .navbar-form.active{
        display: block!important;
        float: left;
        border: none;
        width: 100%;
        padding: 0;
        margin: 0;
    }
    
    .menu-first{
        margin: 0!important;
        padding: 0;
        width: 100%;
        background: #fff;
    }
    
    .menu-first > li{
        clear: both;
        line-height: 45px;
        width: 100%;
        background: #FCFCFC;
        border-top: 1px solid #E3E3E3;
    }
    
    .menu-first > li a{
        color: #282828;
        width: 100%;
        display: block;
        margin: 0;
        padding-left: 40px;
        height: 100%;
    }
    
    .menu-first > li a:hover{
        text-decoration: none;
    }
    
    .mobile-no-padding{
        padding: 0;
    }
    
    #logo{

    }
}

@media screen and (max-width: 1400px){
    #carousel_wrapper .next {
        right: 45.5%;
    }
    #carousel_wrapper .prev {
        left: 45.5%;
    }
}

@media screen and (max-width: 975px){
    #carousel_wrapper .next {
        right: 44%;
    }
    #carousel_wrapper .prev {
        left: 44%;
    }
}

@media screen and (max-width: 767px){
    .map_type{
        margin-bottom: 50px !important;
    }
    
    .bottom_btn{
        left: 47% !important;
    }
    
    #carousel_wrapper .articles_title {
        margin-bottom: 0px !important;
        margin-top: 0px !important;
    }
    .owlNavigation{
        display: none !important;
    }
}

@media (max-width: 594px){
    .module_title{
        font-size: 35px;
    }

    .category_holder:nth-of-type(4n){
        margin-right: 14px;
    }
    
    .gauge_item{
        zoom: 50%;
    }

    span.big_phone{
        font-size: 30px;
    }

    #slider_wrapper {
        visibility: hidden;
        height: 102px;
    }

    .shield_image_holder{
        width: 100%;
    }

    .shield_image_holder img{
        max-width: 100%;
    }
    
    .slogan_type,.newsletter_type,.article_type,.contacts_type{
        padding-left: 15px;
        padding-right: 15px;
    }
    
    #footer_wrapper{
        line-height: normal;
        text-align: center;
        padding-top: 40px;
        padding-bottom: 20px;
        
    }

    .mini_shields_type img{
        min-width: 100%;
    }
    
    .footer.right{
        width: 100%;
    }
    
    .product_left_col{padding: 0;}
    
    .category_holder:nth-of-type(n){
        margin-right: 0px;
    }
    
    .category_holder:nth-of-type(n+1){
        clear: both;
    }
    
    .newsletter_type label{
        float: none!important;
    }
    
    .newsletter_input{
        clear: both;
        float: none;
        text-align: left;
    }
    
    .newsletter_form{
        float: none!important;
        display: inline-block;
    }
    
    .newsletter_type{
        text-align: center;
    }
    
    .newsletter_type button{
        float: none!important;
        clear: both;
    }
    
    .categories_holder{
        padding-left: 15px;
        padding-right: 15px;
    }
    
    .contacts_form .row .col-md-6,.contacts_form .row .col-md-12{
        padding: 0;
    }
    #logo{
        width: 50%;
        display: inline-block;
        margin-top: 11%;
        margin: 0 auto;
        position: absolute;
        top: 35px;
        left: 0;
        right: 0;
    }

    #logo img{
        max-width: 100%;
    }
    
    #mobile_menu_button{
        float: right;
        width: 48px;
        height: 48px;
        padding: 0px;
        //background: url('../img/menu.png') no-repeat top left;
        cursor: pointer;
        margin-top: 30px;
        font-size: 28px;
    }
    
    .navbar-form{
        display: none!important;
    }
    
    .navbar-form.active{
        display: block!important;
        float: left;
        border: none;
        width: 100%;
        padding: 0;
        margin: 0;
    }
    
    .menu-first{
        margin: 0!important;
        padding: 0;
        width: 100%;
        background: #fff;
    }
    
    .menu-first > li{
        clear: both;
        line-height: 45px;
        width: 100%;
        background: #FCFCFC;
        border-top: 1px solid #E3E3E3;
    }
    
    .menu-first > li a{
        color: #282828;
        width: 100%;
        display: block;
        margin: 0;
        padding-left: 37px;
        height: 100%;
    }
    
    .menu-first > li a:hover{
        text-decoration: none;
    }
    
    .mobile-no-padding{
        padding: 0;
    }

    .bottom_btn{
        background-size: 100%;

        width: 10%;
        left: 47%;
    }
    
    #logo{
        margin: 0 auto;
        position: absolute;
        top: 35px;
        left: 0;
        right: 0;
    }


}
@media screen and (min-width: 991px) {
    #mobile_menu_button {
        display: none;  
    }
}