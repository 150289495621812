/*
To change this template, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 19-Dec-2016, 10:58:06
    Author     : Lukas Ružauskas <lukas@svetaine.lt>
*/

#fullModal .bx-wrapper, #fullModal .bx-viewport {
    max-height: 800px !important; //provide height of slider
    /*height: 200px!important;*/
/*    max-width: 600px!important;*/
    /*width: 600px!important;*/
}

.portfolio_type .categories_holder ul {
    padding: 0;
}
#portfolio_prev, #portfolio_next {
    cursor: pointer;
}
.portfolio_image_holder img {
    max-width: 100%;
    min-width: 100%;
}

.portfolio_image_holder {
   position: relative;
}

.portfolio_image_holder > .portfolio_title_holder {
   transition: all .5s ease-in-out;
}

.portfolio_title_holder {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    visibility: hidden;
}

.portfolio_image_holder:hover > .portfolio_title_holder {
    visibility: visible;
    background: #3f3f3f;
    background-repeat: no-repeat;
    background-size: 30%;
    opacity: 0.86;
    cursor: pointer;
}

.portfolio.block_grid.four-up{
    padding-left: 1px;
    padding-right: 1px;
    list-style: none;
}

.portfolio_type .module_title{
    margin-bottom: 40px;
    margin-top: 40px;
}

.portfolio_type{
    padding-bottom: 70px;
    border-top: 1px solid #e3e3e3;
    background: #fff;
    position: relative;
    float: left;
    width: 100%;
}

.portfolio.block_grid.four-up li{
    padding: 1px;
    padding-top: 0px;
    padding-bottom: 2px;
    width: 25%;
    float: left;
}

.big_photos_type{
    width: 100%;
    float: left;
    background: #f9f9f9;
}

.big_photos_holder{
    float: left;
    width: 100%;
    text-align: center;
}

.big_photos_holder img{
    display: inline-block;
    max-width: 100%;
}

@media (max-width: 768px) {
.portfolio.block_grid.four-up li{
        width: 50%;
    }
}

.text_block img{
    max-width: 100%;
}

.port_images_holder {
    width: 100%;
    height: 100%;
    overflow: hidden;
    top: 0;
    left: 0;
    position: absolute;
}

.portfolio_title_holder h5{
    font-size: 100%;
    /*font-size: 2.1vw;*/
    font-size: 36px;
    color: #fff;
    font-weight: 100!important;
    width: 100%;
    text-align: center;
    position: absolute;
    top: 30%;
    padding: 0 12%;
}

.portfolio_title_holder h6 {
    color: #FFF;
    width: 100%;
    /*display: inline-block;*/
    text-align: center;
    font-weight: 100!important;
    position: absolute;
    bottom: 15%;
   /* font-size: 1.2vw;*/
}


@media (min-width: 992px) and (max-width: 1200px) {
    .category_holder:nth-of-type(4n){
        margin-right: 14px;
    }
    
    .category_holder:nth-of-type(3n){
        margin-right: 0px;
    }
    
    .slogan_type,.newsletter_type,.article_type,.contacts_type{
        padding-left: 15px;
        padding-right: 15px;
    }
    
    .category_holder:nth-of-type(3n+1){
        clear: both;
    }
}


.portfolio_type .categories_holder ul {
    list-style: none;
}

.portfolio_type .categories_holder ul li{
    display: inline-block;
    color: #545862;
    border: 1px solid #e0e0e0;
    padding: 12px;
    cursor: pointer;
    margin-right: 2px;
    margin-bottom: 5px;
}

.portfolio_type .categories_holder ul li:hover{
    color: #fff;
    border: 1px solid #e0e0e0;
    background: #e0e0e0;
    cursor: pointer;
}

.portfolio_type .categories_holder ul li.active{
    color: #fff;
    border: 1px solid #3d424d;
    background: #3d424d;
    cursor:initial;
}

.portfolio_more_btn_holder{
    width: 100%;
    text-align: center;
    margin-top: 60px;
}

.portfolio_more_btn{
    display: inline-block;
    color: #545862;
    border: 1px solid #e0e0e0;
    padding: 12px;
    cursor: pointer;
    margin-right: 2px;
    margin-bottom: 5px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.portfolio_more_btn:hover{
    color: #545862;
    border: 1px solid #e0e0e0;
    background: #e0e0e0;
    cursor: pointer;
}

.portfolio_more_btn:active{
    color: #fff;
    border: 1px solid #3d424d;
    background: #3d424d;
}

.portfolio_more_btn.active{
    display: none;
}

.portfolio_wrapper {
    float: left;
    width: 100%;
}

ul.portfolio.block-grid.four-up.mobile-two-up.more_content {
    display: none;
}

ul.portfolio.block-grid.four-up.mobile-two-up.more_content.active {
    display: block;
}

@media (max-width: 768px) {
    .portfolio_more_btn, .portfolio_type .categories_holder ul li {
        padding: 4px;
        font-size: 13px;
    }
}

ul.portfolio {
    list-style-type: none;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
}

.col-md-2.portfolio_item, .col-md-3.portfolio_item {
    padding: 0;
    float: none;
    display: inline-block;
    margin: 0 -2px;
}

@media (min-width: 992px) and (max-width: 1366px) {
    .col-md-2.portfolio_item {
        width: 20%;
    }
}

@media (min-width: 450px) and (max-width: 960px) {
    .col-md-2.portfolio_item {
        width: 50%;
    }
}

.portfolio_description {
    display: none;
}



/* X */
/*.close-hairline, .tos-close span {
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
  overflow: hidden;
  &:hover {
    &::before, &::after {
      background: #fff;
    }
  }

  &::before, &::after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
  }
  &::before {
    -ms-transform: rotate(45deg);  IE 9 
    -webkit-transform: rotate(45deg);  Chrome, Safari, Opera 
    transform: rotate(45deg);
    height: 1px;
  }
  &::after {
    -ms-transform: rotate(-45deg);  IE 9 
    -webkit-transform: rotate(-45deg);  Chrome, Safari, Opera 
    transform: rotate(-45deg);
    height: 1px;
  }
}*/