/* Languages */

#languages {
    margin: 0px;
    list-style: none;
    position: absolute;
    right: 3px;
    top: 3px;
    z-index: 1;
}

#languages .lang {
    margin-left: 5px;
    display: block;
    float: left;
}

#languages .lang a {
    padding: 3px 5px;
    color: #1c1c1c;
    text-transform: uppercase;
}

#languages .lang a.active,
#languages .lang a:hover {
    color: #fff;
    background: #014F8B;
}
