
/* -----------------------------------------
   Carousel
----------------------------------------- */

#carousel_wrapper {
    padding: 0 0 40px 0;
    float: left;
    width: 100%;
}    
#carousel_wrapper .owl-carousel {
    padding: 0 30px;
}
#carousel_wrapper .owl-carousel .item {
    padding: 10px 0px;
    margin: 0px;
    text-align: center;
    height: 90px;
}
#carousel_wrapper .owl-carousel .item .helper {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}
#carousel_wrapper .owl-carousel .item img {
    vertical-align: middle;
    max-height: 90px;
}
#carousel_wrapper .next,
#carousel_wrapper .prev {
    position: absolute;
    top: 41%;
    width: 41px;
    height: 41px;
    cursor: pointer;
}
#carousel_wrapper .next {
    right: 47%;
    background: url('../img/carousel-right.png') no-repeat 0 0;
}
#carousel_wrapper .prev {
    left: 47%;
    background: url('../img/carousel-left.png') no-repeat 0 0;
}
#carousel_wrapper .next:hover {
    background: url('../img/carousel-righth.png') no-repeat 0 0;
}
#carousel_wrapper .prev:hover {
    background: url('../img/carousel-lefth.png') no-repeat 0 0;
}

#carousel_wrapper .next:hover, #carousel_holder .next:focus,
#carousel_wrapper .prev:hover, #carousel_holder .prev:focus {
   background-color: transparent;
   box-shadow: none !important;
   filter: brightness(110%);
}
#carousel_wrapper .next:active,
#carousel_wrapper .prev:active {
   background-color: transparent;
   box-shadow: none !important;
}

#carousel_wrapper .articles_title{
    text-align: center;
    color: #b0b0b0;
    text-transform: uppercase;
    font-weight: 700;
    font-family: "Roboto",Helvetica,Arial,sans-serif;
    margin-top: 55px;
    font-size: 24px;
    margin-bottom: 50px;
}

.owl-next {
    position: absolute;
    top: 40%;
    right: 0;
    font-size: 70px;
    color: #000;
}

.owl-prev {
    position: absolute;
    top: 40%;
    left: 0;
    font-size: 70px;
    color: #000;
}

.slogan_type .owl-next {
    color: #FFF;
}

.slogan_type .owl-prev {
    color: #FFF;
}

span.slogan_img {
    display: block;
    text-align: center;
}

span.slogan_img img {
    border-radius: 50%;
}

.owl-carousel .owl-item img {
    width: auto;
    display: block;
    margin: 0 auto;
}

.owl-carousel .owl-stage {
    display: table;
}

.owl-carousel .owl-item {
    display: table-cell;
    float: none;
    vertical-align: middle;
}