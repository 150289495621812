#mini_banners_wrapper {
    background: #e6e2df;
}
#mini_banners_wrapper .min_banners li {
    position: relative;
    padding-top: 10px; 
    padding-bottom: 7px; 
    margin-left: 0px;
    color: #4a4949;
}
.min_banners li .min_banner_img {
    margin-right: 10px;
}
.min_banners li .min_banner_title {
    text-transform: uppercase;
    font-weight: 300;
    margin-top: 10px;
    font-size: 16px;
    line-height: 16px;
}
.min_banners li .min_banner_text {
    font-weight: 300;
    font-size: 12px;
}
.min_banners li a.on_flash {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
}