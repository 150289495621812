/* -----------------------------------------
                Carousel
----------------------------------------- */

#carousel_wrapper {
    padding: 0 0 40px 0;
}    
#carousel_wrapper .owl-carousel {
    padding: 0 30px;
}
#carousel_wrapper .owl-carousel .item {
    padding: 10px 0px;
    margin: 10px;
    text-align: center;
    height: 90px;
}
#carousel_wrapper .owl-carousel .item .helper {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}
#carousel_wrapper .owl-carousel .item img {
    vertical-align: middle;
    max-height: 90px;
}
#carousel_wrapper .next,
#carousel_wrapper .prev {
    position: absolute;
    top: 55%;
    width: 32px;
    height: 32px;
    cursor: pointer;
}
#carousel_wrapper .next {
    right: 0px;
    background: url('../img/next-horizontal.png') no-repeat 0 0;
}
#carousel_wrapper .prev {
    left: 0px;
    background: url('../img/prev-horizontal.png') no-repeat 0 0;
}
#carousel_wrapper .next:hover, #carousel_holder .next:focus,
#carousel_wrapper .prev:hover, #carousel_holder .prev:focus {
    background-position: -32px 0;
}
#carousel_wrapper .next:active,
#carousel_wrapper .prev:active {
    background-position: -64px 0;
}