/*
To change this template, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 14-Dec-2016, 13:24:39
    Author     : Lukas Ružauskas <lukas@svetaine.lt>
*/


.category_image.circle img {
    transition: all .5s ease-in-out;
    display: inline;
    height: 100%;
    width: auto;
    position: absolute;
    top: -9999px;
    bottom: -9999px;
    left: -9999px;
    right: -9999px;
    margin: auto;
    -webkit-backface-visibility: hidden;
}
.category_image.circle img:hover {
    -webkit-transform: scale(1.4);
    -moz-transform: scale(1.4);
    -ms-transform: scale(1.4);
    -o-transform: scale(1.4);
    transform: scale(1.4);    
}

.category_image.circle {
    width: 177px;
    height: 177px;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    margin: 0 auto;
}