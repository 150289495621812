/*
To change this template, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 16-Dec-2016, 16:16:40
    Author     : Lukas Ružauskas <lukas@svetaine.lt>
*/

.client_label {
    display: block;
}

.client_item {
    vertical-align: middle;
}

.client_item_image_holder {
    width: 150px;
    height: 150px;
    display: inline-table;
}

.client_item_image_holder a {
    display: table-cell;
    vertical-align: middle;
}

.client_item_image_holder span {
    display: table-cell;
    vertical-align: middle;
}

.client_item_image_holder img {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
}

.client_item_image_holder img:hover {
    filter: none;
}