

/* Third meniu */

#bottom_menu {
    color: #525252;
}
#bottom_menu ul li {
    list-style: none;
    margin: 8px 0;
}
#bottom_menu ul li a {
    color: #525252;
    line-height: 14px;
    font-size: 16px;
}
#bottom_menu ul li a:hover {
    text-decoration: underline;
}
