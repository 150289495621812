/* -----------------------------------------
                Helpers
----------------------------------------- */

@import 'helpers/variables';
@import 'helpers/mixins';

/* -----------------------------------------
                Base
----------------------------------------- */

@import 'base/colors';
@import 'base/fonts';

/* -----------------------------------------
                Layout
----------------------------------------- */

@import 'layout/header';
@import 'layout/wrappers';
@import 'layout/body';
@import 'layout/footer';

/* -----------------------------------------
                Components
----------------------------------------- */

@import 'components/block-grid';
@import 'components/breadcrumbs';
@import 'components/buttons';
@import 'components/titles';
@import 'components/pagination';
@import 'components/fullModal';
/* -----------------------------------------
                Menu Modules
----------------------------------------- */

@import 'menu_modules/article';
@import 'menu_modules/articles';

@import 'menu_modules/gallery';
@import 'menu_modules/galleries';
@import 'menu_modules/partners';

@import 'menu_modules/categories';
@import 'menu_modules/products';
@import 'menu_modules/services';
@import 'menu_modules/clients';
@import 'menu_modules/portfolio';
@import 'menu_modules/achievements';
@import 'menu_modules/slogan';

/* -----------------------------------------
                Main Modules
----------------------------------------- */

@import 'main_modules/menu/first_menu';
@import 'main_modules/menu/second_menu';
@import 'main_modules/menu/third_menu';

@import 'main_modules/banners';
@import 'main_modules/carousel';

@import 'main_modules/languages';
@import 'main_modules/logo';
@import 'main_modules/mini_banners';

@import 'main_modules/query_form';
@import 'main_modules/slider';
@import 'main_modules/socialicons';

@import 'base/mobile';

/* -----------------------------------------
                Vendors
----------------------------------------- */

@import 'vendors/tos';
@import 'vendors/owl_carousel';
@import 'vendors/_nivo_slider';
@import 'vendors/_bx_slider';