
/* Banners */

#banners_wrapper {
    padding-top: 20px;
}
.banners li {
    position: relative;
}
.banners li a.on_flash {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
}

